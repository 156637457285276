






























import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { DashboardViewModel } from '@/models/dashboard/DashboardViewModel';

export default Vue.extend({
  name: 'holdgennemsnit-view',
  props: {
    viewModel: {
      type: Object as () => DashboardViewModel
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  async mounted() {
    await this.loadClassSubjectAverage(this.viewModel);
  },
  methods: {
    ...mapActions('dashboardStore', ['loadClassSubjectAverage']),

  },
  computed: {
    ...mapGetters('dashboardStore', ['groupEntries', 'groupKeyLabel', 'groupValueLabel', 'infoboxLabel', 'infoboxValue']),
    
  },
  watch: {
    'viewModel': {
      deep: true,
      async handler(newValue) {
        await this.loadClassSubjectAverage(this.viewModel);
        console.log(['loadClassSubjectAverage', newValue]);
      }
    }
  }
})
